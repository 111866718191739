import React from "react";
import { graphql } from "gatsby";
import BlockContent from "../components/content/block-content";
import PageLayout from "../components/layout/page-layout";

import style from "../styles/page-styles/article-page-template.module.scss";

// helpers
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";

function ArticlePage({ data }) {
  const article = data.sanityArticle;

  return (
    <PageLayout sticky>
      <div className={`${style.pageContainer}`}>
        <div className={style.content}>
          <h1 className={style.pageTitle}>{article.title}</h1>
          {article.mainImage && article.mainImage.asset && (
            <figure>
              <img
                className={style.mainImg}
                src={imageUrlFor(buildImageObj(article.mainImage)).fit("crop").url()}
                alt={article.mainImage.alt}
              />
              {article.mainImage.caption && (
                <figcaption className={style.caption}>
                  <em>{article.mainImage.caption}</em>
                </figcaption>
              )}
            </figure>
          )}
          <div className={style.textContainer}>
            <BlockContent blocks={article._rawBody} />
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default ArticlePage;

export const articleQuery = graphql`
  query articleQuery($id: String!) {
    sanityArticle(id: { eq: $id }) {
      _id
      _rawBody
      mainImage {
        alt
        caption
        asset {
          _id
        }
        crop {
          bottom
          left
          right
          top
          _type
          _key
        }
        hotspot {
          _key
          _type
          height
          width
          x
          y
        }
      }
      id
      title
    }
  }
`;
